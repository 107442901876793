<div class="hero-image">
  <div class="hero-text">
      <h1>Careers at Sarsis</h1>
      <h3>Your talent. Our vision.</h3>
  </div>
</div>

<div class="container">
  <div class="row careers">
    <div class="col-sm-12">
      <h2><strong>Employment Opportunities</strong></h2>
      <br />
      <p>For the following positions please email: <a href="mailto:hhuambachano@sarsis.com">hhuambachano&#64;sarsis.com</a></p>
      <div class="job-links">
        <br />
      <a>No Employment Opportunities at this moment</a>
      </div>
    </div>
  </div>
</div>
